import React from "react";
import CardAcademy from "../../components/CardAcademy";
import "../../index.css"

const Academy = () => {
    return (
        <>
            <CardAcademy/>
        </>
    )
}

export default Academy