export const VerifSubDomain = () => {
    const url = window.location.href;
    
  
    // Verifique se a URL contém um subdomínio após a barra (http://dominio.com.br/subdominio/)
    const backSubdomainMatch = url.match(/https?:\/\/[^/]+\/([^/]+)/);
    if (backSubdomainMatch) {
      return backSubdomainMatch[1];
    } else {
        // Verifique se a URL contém um subdomínio na frente (http://subdominio.dominio.com.br)
        const parts = url.split('.');
        const subdomain = parts[0].replace('https://', '');
        if(subdomain == 'www' || subdomain == 'http://localhost:3000/'){
            return 'demo'
        }
        return subdomain
    }
  }
  