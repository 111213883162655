import styled from "styled-components";

export const Container = styled.div`
    z-index: 3;
    width: var(--SideBar-width);
    min-height: 100vh;
    height: auto;
    padding: 10px;
    position: fixed;
    left: 0;
    background-color: var(--Background-sideBar);
    border: 1px solid var(--Toggle-color);
    border-top: none;
    border-bottom: none;
    border-left: none;
    display: flex;
    flex-direction: column;
    margin-top: 72px;
    align-items: center;

`;

export const PrimaryButtonSideBar = styled.button`
    background: var(--Secondary-Background);
    border: 2px solid var(--Toggle-color);
    padding: 15px;
    width: 100%;
    height: 32px;
    color: var(--Toggle-color);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
`;

