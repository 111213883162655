import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useControles } from "../../../context/controles.context";
import { MinhasApostas } from "../../../services/betfive";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '15px 5px',
    width: '90%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--Secondary-Background)',
    borderRadius: '8px',
    gap: 2,
    
  };

const ModalMinhasApostas = () => {
    const {open, setOpen, tituloModal, urlModal, setopenMinhasApostas, openMinhasApostas} = useControles();

    const [lista, setlista] = useState([]);

    const handleOpen = () => setopenMinhasApostas(true);
    const handleClose = () => setopenMinhasApostas(false);

    const fetchMinhasApostas = async () => {
        const response = await MinhasApostas()
        console.log(response.data)
        setlista(response.data)
    }

    useEffect(() => {
        fetchMinhasApostas()
    }, [])
  
    return (
      <div>
        <Modal
          open={openMinhasApostas}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h2 style={{color: 'var(--Primary-text)', fontSize: '32px'}}>Minhas Apostas</h2>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'scroll', width: '100%', gap: '5px'}}>
                {lista.map((item, indice) => {
                    return (
                        <div style={{color: 'white', display: 'flex', justifyContent: 'space-between', padding: '2px 5px', border: '1px solid white'}}>
                            <span>
                                {item.created_at}
                            </span>
                            <span>
                                {item.return_value}
                            </span>
                            <span>
                                {item.value}
                            </span>
                            <span>
                                {item.using_bonus}
                            </span>
                            <span>
                                {item.status}
                            </span>
                        </div>
                    )   
                })}
            </div>
          </Box>
        </Modal>
      </div>
    )
}

export default ModalMinhasApostas