import styled from "styled-components";

export const StyledDiv = styled.div`
    background-color: var(--Primary-background);
    color: var(--Primary-text);
    width: 100%;
    height: 100vh;
    display: flex; 
    flex-direction: column; 
    gap: 10;
`;

export const LogoMobile = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 0;
`;

export const PageWrapper = styled.div`
    padding: 0 15px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p{
        width: 75%;
        text-align: center;
        align-items: center;
        align-self: center;
    }
`;

export const WizardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px;
    background-color: var(--Transluced-background);
    padding: 40px 0px;
    gap: 10px;
`;

export const DivInstrucoes = styled.div`
    width: 100%; 
    display: flex; 
    flex-direction: column;
    align-items: center;
    .divInstrucoes{
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
    }
    .divInstrucao{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .numeroInstrucao{
        width: 28px;
        height: 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background: var(--Toggle-color);
    }
    .instrucao{
        width: 60%;
    }
    .icon{
        font-size: 40px;
        background: var(--Toggle-color);
        padding: 5px;
        border-radius: 6px;
        
    }
`;