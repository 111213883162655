import { Outlet } from "react-router-dom"
import { useWhitelabel } from "../../context/whitelabel.context"
import LoadingMain from "../../components/Loadings/LoadingMain"
import { useEffect } from "react"
import { VerifSubDomain } from "../../utils/verifSubdomain"

export const SignedOutPages = () => {
    const {loadedWhitelabel, dominio, setDominio, VerifySubDomain} = useWhitelabel()

    const fetchWhitelabel = async () => {
        const response = await VerifySubDomain(localStorage.getItem('@Subdominio:BetBuilder'))
        return response
      }
    
      useEffect(()=>{
        if(localStorage.getItem('@Subdominio:BetBuilder')){
            fetchWhitelabel()
        }
        
      }, [])

    
    return (
        <>
               {!loadedWhitelabel ? 
                <div style={{display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh', backgroundColor: '#000', alignItems: 'center', justifyContent: 'center'}}>
                    <LoadingMain/>
                </div> 
                : 
                <Outlet/>
               } 
        </>
    )
}

