import styled from "styled-components";

export const StyledDiv = styled.div`
    background-color: var(--Primary-background);
    color: var(--Primary-text);
    width: 100%;
    height: 100%;
    display: flex; 
    flex-direction: column; 
    gap: 10;
`;

export const StyledDivDesktop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--Primary-background);
    width: 100%;
    padding: 50px;
    color: var(--Primary-text);
`;

export const LogoMobile = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 0;
`;

export const PageWrapper = styled.div`
    padding: 0 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const PageWrapperDesktop = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
`;

export const WizardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px;
    background-color: var(--Transluced-background);
    padding: 40px 0px;
    gap: 10px;
`;

export const IframeContainer = styled.div`
    width: 90%;
    min-width: 400px;
    height: 540px;
    margin: 20px;
`;

export const IframeContainerMobile = styled.div`
    width: 100%;
    max-width: 700px;
    height: 540px;
    margin: 20px;
`;