import React, { createContext, useContext, useEffect, useState } from "react";
import instance from "../services/instance";
import ExampleNew from '../assets/exemploNew.png'
import { useWhitelabel } from "./whitelabel.context";

const ClienteContext = createContext({})
ClienteContext.displayName = 'ClienteContext'

const ClienteProvider = ({ children }) => {
    const {clienteId} = useWhitelabel()

    const [listaSlideShow, setListaSlideShow] = useState([
        "https://api.kappaofertas.com/teste/AviatorBanner.gif",
       
    ])

    const [listaNews, setListaNews] = useState([{
        titulo: "Confira a Live",
        subtitulo: "Estamos esperando você nesse exato momento!",
        urlImg: ExampleNew,
        url: "./live"
    }])

    const [aulas, setAulas] = useState([])



    

    //METODOS

    const GetBanners = async () => {
        let lista = []

        try {
            const response = await instance.get(`/Label/GetBanners?ClienteId=${clienteId}`)
            for(let i in response.data){
                if(response.data[i].ativo == 1){
                    //console.log(response.data[i])
                    lista.push(response.data[i].banner)
                    
                }
            }
            setListaSlideShow(lista)
            return lista
        } catch(error){
            //console.log('Erro no ClienteContext: ', error)
            return error
        }
    }


    const GetAulas = async () => {
        let lista = []

        try {
            const response = await instance.get(`/Label/GetAcademyLabel?ClienteId=${clienteId}`)
            for (let i in response.data){
                if(response.data[i].ativo == 1){
                    //console.log(response.data[i])
                    lista.push(response.data[i])
                }
            }
            setAulas(lista)
            return lista
        } catch (err) {

        }
    }
    



    const GetControleFinanceiro = async (userId) => {
        try {
            const response = await instance.get(`/Perfil/GetControleFinanceiro?id=${userId}`)
            return response.data
        } catch (error) {
            return error
        }
    }

    const InsertControleFinanceiro = async ({userId, entrada}) => {
        try {
            const response = await instance.post(`/Perfil/InsertControleFinanceiro`, {
                userId,
                entrada
            })
            return response.data
        } catch (error) {
            return error
        }
    }

    const UpdateControleFinanceiro = async ({userId, entrada}) => {
        try {
            const response = await instance.post(`/Perfil/UpdateControleFinanceiro`, {
                userId,
                entrada
            })
            return response.data
        } catch (error) {
            return error
        }
    }
    

    return (
        <ClienteContext.Provider value={{     
               listaNews,
               listaSlideShow,
               GetBanners,
               GetAulas,
               aulas,
               setAulas,
               GetControleFinanceiro,
                InsertControleFinanceiro,
                UpdateControleFinanceiro
        }}>
            {children}
        </ClienteContext.Provider>
    )
}


export const useCliente = () => {
    const context = useContext(ClienteContext)

    if(!context) {
        throw new Error("Erro ao usar o useCliente")
    }

    return context;
}

export {ClienteContext, ClienteProvider}