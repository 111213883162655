import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;


    
   

   .divPai{
        width: calc(100% - var(--SideBar-width));
        padding: 40px 60px;
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        align-content: flex-start;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        
   }

   @media screen and (max-width: 1024px){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center
      align-self: center;

      .divPai{
          width: 98%;
          align-items: center;
          padding: 20px 0;
          gap: 50px;
          padding-bottom: 5rem;
      }
     
   }
`;