import axios from "axios";
 
const instance = axios.create({
  baseURL: "https://smarttips.lineragamessub.com.br",
  headers: {
    Authorization: ""
  }
});


export default instance;






