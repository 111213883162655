import React, {useState} from "react";
import { useControles } from "../../../context/controles.context";
import { PrimaryButton } from "../../../globalComponents";
import { PageWrapper, LogoMobile, StyledDiv, DivInstrucoes } from "./styles";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Icon } from '@iconify/react';
import { useAuth } from "../../../context/auth.context";
import Checkbox from '@mui/material/Checkbox';
import { useWhitelabel } from "../../../context/whitelabel.context";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
    
      margin: theme.spacing(0.2),
      border: 0,
      '&.Mui-disabled': {
        border: 0,
        
        
      },
      '&:not(:first-of-type)': {
        borderRadius: theme.shape.borderRadius,
      },
      '&:first-of-type': {
        borderRadius: theme.shape.borderRadius,
      },
    },
   
  }));


  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CardStepTwo = () => {
    const {isMobile, setWizardAuxiliar, setActiveWizardStep} = useControles()
    const navigate = useNavigate()
    const {setStatusWizard, UpdateWizardBool} = useAuth()

    const {logoHorizontal} = useWhitelabel()
    

    const [checkbox, setCheckbox] = useState(false)

    const [sistemaOperacional, setSistemaOperacional] = useState('IOS');

    const handleChange = (event, newSistemaOperacional) => {
        setSistemaOperacional(newSistemaOperacional);
    };

    const fetchData = async () => {
        const response = await UpdateWizardBool()
        return response
    }

    return (
        <>
                <StyledDiv>
                    <LogoMobile>
                            <img src={logoHorizontal} style={{width: 150,}}></img>
                    </LogoMobile>
                    <PageWrapper>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, }}>
                                <h1>Por último,</h1>
                                <p>instale o aplicativo criando um atalho na tela inicial do seu dispositivo móvel:</p>
                            </div>
                            <div style={{margin: 20}}>
                                {/*toggle button*/}
                                <Paper
                                    elevation={0}
                                    sx={{
                                    display: 'flex',
                                    border: (theme) => `1px solid ${theme.palette.divider}`,
                                    flexWrap: 'wrap',
                                    background: 'var(--Secondary-Background)',
                                    alignItems: 'center',         
                                    justifyContent: 'center',
                                    width: 'auto !important'
                                    }}
                                >
                                    <StyledToggleButtonGroup
                                    size="small"
                                    value={sistemaOperacional}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="text alignment"
                                    
                                    >

                                    <ToggleButton value="IOS" aria-label="left aligned" style={{minWidth: 150, color: 'var(--Primary-text)',
                                    background: sistemaOperacional == 'IOS' &&'var(--Toggle-color)'}}>
                                        IOS
                                    </ToggleButton>
                                    <ToggleButton value="Android" aria-label="centered" style={{minWidth: 150, color: 'var(--Primary-text)', background: sistemaOperacional == 'Android' &&'var(--Toggle-color)'}}>
                                        Android
                                    </ToggleButton>
                                    
                                    </StyledToggleButtonGroup>
                                </Paper>

                                {/*info ios*/}
                                {sistemaOperacional == 'IOS' && 
                                <>
                                    <DivInstrucoes>
                                        <h3 style={{
                                            textAlign: 'center',
                                            padding: '30px 0',
                                            fontSize: '20px',
                                            
                                        }}>Siga o passo a passo a seguir:</h3>
                                        <div className="divInstrucoes">

                                        
                                        <div className="divInstrucao"> 
                                            <span className="numeroInstrucao"> 1 </span>
                                            <span className="instrucao"> Clique no símbolo de compartilhar</span>
                                            <Icon icon="octicon:share-24" className="icon"/>
                                        </div>

                                        <div className="divInstrucao"> 
                                            <span className="numeroInstrucao"> 2 </span>
                                            <span className="instrucao"> Clique no botão de “Tela de Início”</span>
                                            <Icon icon="icon-park-outline:add" className="icon" />
                                        </div>

                                        <div className="divInstrucao"> 
                                            <span className="numeroInstrucao"> 3 </span>
                                            <span className="instrucao"> Confirme a instalação</span>
                                            <Icon icon="gg:check-r" className="icon"/>
                                        </div>
                                        
                                        </div>
                                    </DivInstrucoes>

                                </>}

                                {/*info android*/}
                                {sistemaOperacional == 'Android' && 
                                <>
                                    <DivInstrucoes>
                                        <h3 style={{
                                            textAlign: 'center',
                                            padding: '30px 0',
                                            fontSize: '20px',
                                            
                                        }}>Siga o passo a passo a seguir:</h3>
                                        <div className="divInstrucoes">

                                        
                                        <div className="divInstrucao"> 
                                            <span className="numeroInstrucao"> 1 </span>
                                            <span className="instrucao"> Clique no botão de opções</span>
                                            <Icon icon="mi:options-vertical" className="icon"/>
                                        </div>

                                        <div className="divInstrucao"> 
                                            <span className="numeroInstrucao"> 2 </span>
                                            <span className="instrucao"> Clique em "Adicionar à tela inicial"</span>
                                            <Icon icon="icon-park-outline:add" className="icon" />
                                        </div>

                                        <div className="divInstrucao"> 
                                            <span className="numeroInstrucao"> 3 </span>
                                            <span className="instrucao"> Confirme a instalação</span>
                                            <Icon icon="gg:check-r" className="icon"/>
                                        </div>
                                        
                                        </div>
                                    </DivInstrucoes>

                                </>}

                                {/*duas bolinhas indicando*/}
                                <div style={{width: '100%', display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'center', margin: '30px 0 0 0'}}>
                                    <div style={{width: 7, height: 7, 
                                        background: sistemaOperacional == 'IOS' ? 'var(--Primary-color)' : 'var(--Toggle-color)', borderRadius: 50}}></div>
                                    <div style={{width: 7, height: 7,  background: sistemaOperacional == 'Android' ? 'var(--Primary-color)' : 'var(--Toggle-color)', borderRadius: 50}}></div>
                                </div>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10}}>
                                <Checkbox {...label} value={checkbox} onChange={()=>setCheckbox(!checkbox)} sx={{
                                        color: 'var(--Primary-color)',
                                        '&.Mui-checked': {
                                        color: 'var(--Primary-color)',
                                        },
                                    }} /> <span>Não mostrar novamente.</span>
                            </div>
                            <div style={{padding: 20}}>
                                <PrimaryButton style={{minWidth: 200}}
                                onClick={()=>{
                                    if(checkbox){
                                        fetchData()
                                    }
                                    setStatusWizard(0)
                                    setActiveWizardStep(1)
                                    navigate('/menu')

                                }}>Prosseguir</PrimaryButton>
                            </div>
                    </PageWrapper>
                </StyledDiv>
        </>
    )
}

export default CardStepTwo