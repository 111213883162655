import styled from "styled-components";

export const StyledDiv = styled.div`
    background-color: var(--Primary-background);
    color: var(--Primary-text);
    width: 100%;
    height: 100vh;
    display: flex; 
    flex-direction: column; 
    gap: 10;
`;

export const StyledDivDesktop = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--Primary-background);
    width: 100%;
    padding: 50px;
    color: var(--Primary-text);
`;

export const LogoMobile = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 0;
`;

export const PageWrapper = styled.div`
    padding: 0 15px;
    height: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const PageWrapperDesktop = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
`;

export const WizardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px;
    background-color: var(--Transluced-background);
    padding: 40px 0px;
    gap: 10px;
`;

export const IframeContainer = styled.div`
    width: 90%;
    min-width: 400px;
    height: 540px;
    margin: 20px;
`;

export const IframeContainerMobile = styled.div`
    width: 100%;
    max-width: 700px;
    height: 540px;
    margin: 20px;
`;

export const FormsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    max-width: 420px;
    border-radius: 20px;
    background-color: var(--Transluced-background);
    padding: 40px 20px;
    gap: 10px;

    @media screen and (min-width: 1025px){
        min-width: 420px;
    }

    div{    
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    div:nth-child(3){
        padding-top: 0px;
    }

    button{
        width: 100%;
        max-width: 400px;
        
    }
    p{
        text-align: center;
        opacity: 0.8;
        font-size: 14px;
    }

    span{
        color: var(--Primary-color);
        opacity: 1;
        cursor: pointer;
        font-size: 14px;
    }
`

export const Forms = styled.div`
    flex-direction: column;
    display: flex;
    width: 100%;
    gap: 20px;
    
    max-width: 400px;

    div{
        display: flex;
        flex-direction: column;
        
        gap: 10px;
    }
`

export const ForgotPassword = styled.div`
    font-size: smaller;
    text-align: right;
    opacity: 0.6;
    cursor: pointer;
    margin-top: -10px;
    

    &:hover{
        opacity: 0.8;
    }
`;