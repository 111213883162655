import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import instance from "../services/instance";
import instanceTelegram from "../services/instanceTelegram";
import { useWhitelabel } from "./whitelabel.context";

const GamesContext = createContext({})
GamesContext.displayName = 'GamesContext'

const GamesProvider = ({ children }) => {
    const {clienteId} = useWhitelabel()
    const [listaJogos, setListaJogos] = useState()
    const [activeGame, setActiveGame] = useState(null)
    //tipoMesas: 
        // 1- offline sem exemplo em interface grafica
        // 2- offline com exemplo em interface grafica
        // 3- online

    

    const GetJogos = async () => {
        try{
            const response = await instance.get(`/Label/GetJogosLabel?ClienteId=${clienteId}`)
            // console.log(response.data)
            setListaJogos(response.data)

        } catch (err) {
            //console.log('Erro no Gamecontext: ', err)
            return err
        }
    }
   
    const GetJogada = async (roletaId) => {
        try{
            const response = await instance.get(`/Label/GetCondicoesJogos?RoletaId=${roletaId}`)
            // console.log(response)
            return response
        } catch (err) {
            return err
        }
    }


    const GetJogadaTelegram = async (roletaId) => {
        try {
            const response = await instanceTelegram.get(`/Inicio/GetMensagensAoVivo?RoletaId=${roletaId}`)
            // console.log(response)
            return response
        } catch (err) {
            return err
        }
    }

    return (
        <GamesContext.Provider value={{     
                listaJogos,
                GetJogos,
                setActiveGame,
                activeGame,
                GetJogada,
                GetJogadaTelegram
        }}>
            {children}
        </GamesContext.Provider>
    )
}


export const useGame = () => {
    const context = useContext(GamesContext)

    if(!context) {
        throw new Error("Erro ao usar o useGame")
    }

    return context;
}

export {GamesContext, GamesProvider}