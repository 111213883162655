import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background: var(--Primary-background);
`;