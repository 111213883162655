import React from "react";
import { useControles } from "../../../context/controles.context";
import { PrimaryButton } from "../../../globalComponents";
import { WizardContainer, PageWrapper, PageWrapperDesktop, StyledDiv, StyledDivDesktop, IframeContainer, IframeContainerMobile, LogoMobile } from "./styles";


import { useNavigate } from 'react-router-dom';
import { useWhitelabel } from "../../../context/whitelabel.context";
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "../../../context/auth.context";
import { useEffect, useState } from "react";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CardStepOne = () => {
    const {linkCadastro, logoHorizontal} = useWhitelabel()
    const {isMobile, setActiveWizardStep, setWizardAuxiliar} = useControles()
    const {UpdateWizardBool} = useAuth()
    const [checkbox, setCheckbox] = useState(false)
    const navigate = useNavigate()

    useEffect(()=>{
        //console.log(checkbox)
    }, [checkbox])

    const fetchData = async () => {
        const response = await UpdateWizardBool()
        return response
    }

    return (
        <>
            {isMobile ? 
                <>
                <StyledDiv>
                    <LogoMobile>
                            <img src={logoHorizontal} style={{width: 150}}></img>
                    </LogoMobile>
                    <PageWrapper>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, }}>
                                <h1>Antes de começar,</h1>
                                <p>faça seu login, ou cadastre-se, na plataforma que analisamos os sinais:</p>
                            </div>
                            <IframeContainerMobile>
                                <iframe style={{width: '100%', height: '100%', borderRadius: 20}} src={linkCadastro}></iframe>
                            </IframeContainerMobile>
                            <div style={{padding: 20}}>
                                <PrimaryButton style={{minWidth: 200}} onClick={()=>{
                                    setActiveWizardStep(3)
                                    
                                }}>Prosseguir</PrimaryButton>
                            </div>
                    </PageWrapper>
                </StyledDiv>
                </> : 
                <>
                <StyledDivDesktop>
                    <PageWrapperDesktop>
                        <WizardContainer>
                            <img src={logoHorizontal} style={{width: 250, paddingBottom: 15}}></img>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, }}>
                                <h1>Antes de começar,</h1>
                                <p>faça seu login, ou cadastre-se, na plataforma que analisamos os sinais:</p>
                            </div>
                            <IframeContainer>
                                <iframe style={{width: '100%', height: '100%', borderRadius: 20}} src={linkCadastro}></iframe>
                            </IframeContainer>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10}}>
                                <Checkbox {...label} value={checkbox} onChange={()=>setCheckbox(!checkbox)} sx={{
                                        color: 'var(--Primary-color)',
                                        '&.Mui-checked': {
                                        color: 'var(--Primary-color)',
                                        },
                                    }} /> <span>Não mostrar novamente.</span>
                            </div>
                            
                            <div style={{display: "flex", flexDirection: "row", justifyContent: 'space-around', alignItems: 'center', gap: 20, width: '100%', maxWidth: '420px', padding: '0 20px'}}>
                                <PrimaryButton style={{width: '100%'}} onClick={()=>{
                                    if(checkbox){
                                        fetchData()
                                    }
                                    setActiveWizardStep(1)
                                    navigate('/Menu')
                                }}>Prosseguir</PrimaryButton>
                            </div>
                        </WizardContainer>
                    </PageWrapperDesktop>
                </StyledDivDesktop>
                </>
            }
        </>
    )
}

export default CardStepOne