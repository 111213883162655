import React, {useState} from "react";
import { Container, PrimaryButtonSideBar } from "./styles";
import { useControles } from "../../context/controles.context";
import { useNavigate } from "react-router-dom";
import { useWhitelabel } from "../../context/whitelabel.context";

const SideBar = () => {
    const {activePage, setActivePage} = useControles()
    const {liveBool, liveLink} = useWhitelabel()
    const navigate = useNavigate()

    const handleChangePage = (pageNumber, page) => {
        setActivePage(pageNumber)
        navigate(`./${page}`)
    }

    return (
        <Container>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '90%', gap: 20}}>
                <PrimaryButtonSideBar 
                style={{
                    border: activePage == 1 && '2px solid var(--Primary-color)', 
                    color: activePage == 1 && 'var(--Primary-color)'}}
                    onClick={() => {handleChangePage(1, '')}}
                    >
                    Início
                </PrimaryButtonSideBar>
                <PrimaryButtonSideBar 
                style={{
                    border: activePage == 2 && '2px solid var(--Primary-color)', 
                    color: activePage == 2 && 'var(--Primary-color)'}}
                    onClick={() => {handleChangePage(2, 'Games')}}
                    >
                    Jogos
                </PrimaryButtonSideBar>
                <PrimaryButtonSideBar 
                style={{
                    border: activePage == 3 && '2px solid var(--Primary-color)', 
                    color: activePage == 3 && 'var(--Primary-color)'}}
                    onClick={() => {handleChangePage(3, 'Academy')}}
                    >
                    Academy
                </PrimaryButtonSideBar>
                <PrimaryButtonSideBar 
                style={{
                    border: activePage == 4 && '2px solid var(--Primary-color)', 
                    color: activePage == 4 && 'var(--Primary-color)'}}
                    onClick={() => {handleChangePage(4, 'Perfil')}}
                    >
                    Perfil
                </PrimaryButtonSideBar>
                
                {liveBool == 1 &&
                    <PrimaryButtonSideBar 
                    style={{
                        border: activePage == 5 && '2px solid var(--Primary-color)', 
                        color: activePage == 5 && 'var(--Primary-color)'}}
                        onClick={() => {handleChangePage(5, 'Live')}}
                        >
                        Live
                    </PrimaryButtonSideBar>    
                }
                

            </div>
        </Container>
    )
}

export default SideBar