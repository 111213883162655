import React from "react";
import CardRegister from "../../components/CardRegister";
import "../../index.css"


const Register = () => {
    return (
        <>
            <CardRegister/>
        </>
    )
}

export default Register