import axios from "axios";



export const GetSaldo = async () => {
    let token = localStorage.getItem("tokenBetfive")
    const headers = {
        'Referer': 'https://www.betfive.io/',
        'Authorization': `Bearer ${token}`,
      };
    try {
        const response = await axios.get(`https://sb-vip5.ngx.bet/company/info`,{
            companyId: "652137e452a1600026fb2640",
            company: {
                _id: "652137e452a1600026fb2640"
            }
        }, { headers })
        return response

    } catch (err) {
        throw err
    }
}


export const LoginBetfive = async (obj) => {
    console.log(obj)
    try {
        const response = await axios.post(`https://proxy.lineragamessub.com.br/LoginBetFive`, obj)
        sessionStorage.setItem("profile_betfive", response.data.loginResponse)
        sessionStorage.setItem("bearer_token", response.data.token)

        sessionStorage.setItem(`@Token:BetBuilder`, response.data.token)
        const obj2 = JSON.parse(response.data.loginResponse)
        console.log(obj2, "obj")

        const save = async () => {
            localStorage.setItem(`@Email:BetBuilder`, obj2.name)
            localStorage.setItem(`@Nome:BetBuilder`, obj2.name)
            localStorage.setItem(`@Telefone:BetBuilder`, obj2.contact)
        }
       await save()

        return response.data
    } catch (err) {
        throw err
    }
}


export const Deposit = async (value) => {
    let token = localStorage.getItem("tokenBetfive")

    const headers = {
    'Referer': 'https://www.betfive.io/',
    'Authorization': `Bearer ${token}`,
    };
    try {
        const response = await axios.post(`https://sb-vip5.ngx.bet/deposit`, {
            method: "PIX",
            value: value
        }, {headers})
        return response
    } catch (err) {
        throw err
    }
}

export const Withdraw = async (value, password, cpf) => {
    let token = localStorage.getItem("tokenBetfive")

    const headers = {
        'Referer': 'https://www.betfive.io/',
        'Authorization': `Bearer ${token}`,
      };
    try {
        const response = await axios.post('https://sb-vip5.ngx.bet/withdraw',
        {
            value: value,
            method: "PIX",
            pix: {
                type: "CPF",
                key: cpf
            },
            password: password
        }, {headers})

        return response
    } catch (err) {
        throw err
    }
}


export const Statistics = async () => {
    let token = localStorage.getItem("tokenBetfive")

const headers = {
    'Referer': 'https://www.betfive.io/',
    'Authorization': `Bearer ${token}`,
  };
    const url = "https://sb-vip5.ngx.bet/statistics/affiliation-general"
    try {
        const response = await axios.get(url, {headers})
        return response
    } catch (err) {

    }
}

//esse metodo funciona
export const MinhasApostas = async () => {
    let token = localStorage.getItem("tokenBetfive")

const headers = {
    'Referer': 'https://www.betfive.io/',
    'Authorization': `Bearer ${token}`,
  };
    const url = "https://sb-vip5.ngx.bet/bet"
    try {
        const response = await axios.get(url, {headers})
        return response
    } catch (err) {
        throw err
    }
}


//esse metodo funciona
export const MeuExtrato = async () => {
    let token = localStorage.getItem("tokenBetfive")

const headers = {
    'Referer': 'https://www.betfive.io/',
    'Authorization': `Bearer ${token}`,
  };
    const url = "https://sb-vip5.ngx.bet/transaction?initial_date=2024-01-10T03:00:00.000Z&final_date=2024-01-11T02:59:00.000Z"
    try {
        const response = await axios.get(url, {headers})
        return response
    } catch (err) {
        throw err
    }
}


//esse metodo funciona
export const ListarDepositos = async () => {
    let token = localStorage.getItem("tokenBetfive")

const headers = {
    'Referer': 'https://www.betfive.io/',
    'Authorization': `Bearer ${token}`,
  };
    const url = "https://sb-vip5.ngx.bet/deposit?initial_date=2024-01-10T03:00:00.000Z&final_date=2024-01-11T02:59:00.000Z&status="
    try {
        const response = await axios.get(url, {headers})
        return response
    } catch (err) {
        throw err
    }
}

//esse metodo funciona
export const ListarSaques = async () => {
    let token = localStorage.getItem("tokenBetfive")
    console.log(token, " token utilizado no listarsaques")
    const headers = {
        'Referer': 'https://www.betfive.io/',
        'Authorization': `Bearer ${token}`,
    };
    const url = "https://sb-vip5.ngx.bet/withdraw?initial_date=2024-01-10T03:00:00.000Z&final_date=2024-01-11T02:59:00.000Z&status="
    try {
        const response = await axios.get(url, {headers})
        return response
    } catch (err) {
        throw err
    }
}

export const SaldoDisponivel = async () => {
    let token = localStorage.getItem("tokenBetfive")

const headers = {
    'Referer': 'https://www.betfive.io/',
    'Authorization': `Bearer ${token}`,
  };

    const url = `https://sb-vip5.ngx.bet/bonus?company=${JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder')).company._id}&context=CASINO&active_bonus=true`
    try {
        const response = await axios.get(url, {headers})
        return response
    } catch (err) {
        // localStorage.clear()
        throw err
    }
}


export const LoginUsuarioBetfive = async (user) => {
    const url = 'https://proxy.lineragamessub.com.br/LoginBetFive/BetFiveLogin'

    try {
        const response = await axios.post(url, user)
        localStorage.setItem('tokenBetfive', response.data.token)
        return response
    } catch (err) {
        throw err
    }
}


export const VerificaToken = async (token) => {

    const url = 'https://sb-vip5.ngx.bet/withdraw/availability'
    try {
        const response = await axios.get(url, {
            'Referer': 'https://www.betfive.io/',
            'Authorization': `Bearer ${token}`,
        })
        localStorage.setItem('tokenBetfive', token)
        return response
    } catch (err) {
        throw err
    }
}

export const LoginApiBetfive = async (obj) => {
    try {
        const url = 'https://sb-vip5.ngx.bet/login';

        const response = await axios.post(url, JSON.stringify(obj))
        return response
    } catch (err) {
        throw err
    }
}


export const GetMobToken = async (token) => {
    try {
        const url = `https://sb-vip5.ngx.bet/cassino-mobi/Token`

        const response = await axios.get(url, {
            headers: {
                'Referer': 'https://www.betfive.io/',
                'Authorization': `Bearer ${token}`,
            }
        })

        return response
    } catch (err) {
        throw err
    }
}
// {
//     "username": "theofm05",
//     "password": "Senha@123",
//     "auth_code": null,
//     "source": "MOBILE"
// }
