import React, { useState } from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryLabel, PrimaryTextInput, PrimaryButton, Logo1, PrimaryInputMask, ErrorMsg, } from "../../globalComponents";
import { StyledDiv, PageWrapper, FormsContainer, Forms, ForgotPassword, StyledDivDesktop, PageWrapperDesktop } from "./styles";


import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/auth.context";
import { useWhitelabel } from "../../context/whitelabel.context";

import InputMask from 'react-input-mask';


const CardRegister = () => {
    const {isMobile, errorMsg, setErrorMsg} = useControles()
    const navigate = useNavigate()
    const { Register } = useAuth()
    const { clienteId, logoVertical } = useWhitelabel()

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [username, setUsername] = useState('')
    
    const [telefone, setTelefone] = useState('')

    

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleRegister();
        }
    }

    

    const handleRegister = async () => {
        if(username.length < 3){
            setErrorMsg('Seu username deve ser igual ao da Betfive.')
            return false
        }
        else if(senha.length < 6){
            setErrorMsg('Sua senha deve ter 6 ou mais caracteres.')
            return false
        } else if (telefone.length <= 11){
            setErrorMsg('Celular invalido ou incompleto.')
            //console.log(telefone.length, telefone)
            return false

        }else if (nome.length < 1) {
            setErrorMsg('Insira seu nome.')
            return false
        }else if (!email.includes('@') && email.length < 3) {
            setErrorMsg('Insira um e-mail válido.')
            return false
        } else {
            const userData = {
                id: 0,
                nome: nome,
                email: email,
                senha: senha,
                clienteId: clienteId,
                telefone: telefone.replace("(", '').replace(")", '').replace(" ", "").replace("-", ''),
                username: username,
                token: ""
                //datahora: new Date()
            }
    
            await Register(userData).then((response)=>{
                //console.log(response)
                setErrorMsg(null)
                navigate('/')
            }).catch((error)=>{
                //console.log(error.response.data.message)
                setErrorMsg(error.response.data.message)
            })
        }

        //....
        
    }

    return (
        <>
            {isMobile ? 
                <>
                <StyledDiv>
                    <PageWrapper>
                        <div style={{
                            display: 'flex', flexDirection: 'column', textAlign: 'left', justifyContent: 'left', gap: 0, paddingBottom: 20, width: '80%',
                            maxWidth: '420px'
                            }}>

                            <h1 style={{fontSize: 36, textAlign: 'left', width: '100%'}}>Cadastro</h1>
                            
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Nome</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu nome"
                                    value={nome} onChange={(e)=>setNome(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>
                                
                                <div>
                                    <PrimaryLabel>Email</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu melhor email"
                                    value={email} onChange={(e)=>setEmail(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>

                                <div>
                                    <PrimaryLabel>Username</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu username"
                                    value={username} onChange={(e)=>setUsername(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>

                                <div>
                                    <PrimaryLabel>Celular</PrimaryLabel>
                                    <PrimaryInputMask
                                        mask="(99) 99999-9999" // Defina a máscara de telefone desejada
                                        placeholder="Informe seu celular"
                                        type="text"
                                        value={telefone} onChange={(e)=>setTelefone(e.target.value)}
                                        onKeyDown={handleKeyPress}
                                    />
                                </div>

                                <div>
                                    <PrimaryLabel>Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua senha" type="password"
                                    value={senha} onChange={(e)=>setSenha(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>
                            </Forms>
                            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton onClick={handleRegister} onKeyDown={handleKeyPress}>Cadastrar-se</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5,}}>
                                    <p>Caso já possua uma conta, </p>
                                        <span onClick={()=>{
                                            navigate(`/${localStorage.getItem('@Subdominio:BetBuilder')}`)
                                        }}>  faça seu login!</span>
                                </div>
                                
                                
                            </div>
                           

                            
                        </FormsContainer>
                    </PageWrapper>
                </StyledDiv>
                </> : 
                <>
                <StyledDivDesktop>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',    height: '100%' ,backgroundImage: 'var(--Background-image)', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 200, maxHeight: 200}}/>
                    </div>

                    <PageWrapperDesktop>

                        
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, paddingBottom: 30, maxWidth: '420px', width: '80%'}}>
                            <h1 style={{fontSize: 30, textAlign: 'left', maxWidth: '420px'}}>
                                Cadastro
                            </h1>
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Nome</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu nome"
                                    value={nome} onChange={(e)=>setNome(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>
                                
                                <div>
                                    <PrimaryLabel>Email</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu melhor email"
                                    value={email} onChange={(e)=>setEmail(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>

                                <div>
                                    <PrimaryLabel>Username</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu username"
                                    value={username} onChange={(e)=>setUsername(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>

                                <div>
                                    <PrimaryLabel>Celular</PrimaryLabel>
                                    <PrimaryInputMask
                                        mask="(99) 99999-9999" // Defina a máscara de telefone desejada
                                        placeholder="Informe seu celular"
                                        type="text"
                                        value={telefone} onChange={(e)=>setTelefone(e.target.value)}
                                        onKeyDown={handleKeyPress}
                                    />
                                </div>

                                <div>
                                    <PrimaryLabel>Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua senha" type="password"
                                    value={senha} onChange={(e)=>setSenha(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>
                            </Forms>

                            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton onClick={handleRegister} onKeyDown={handleKeyPress}>Cadastrar-se</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5,}}>
                                    <p>Caso já possua uma conta, </p>
                                        <span onClick={()=>{
                                            navigate(`/${localStorage.getItem('@Subdominio:BetBuilder')}`)
                                        }}>  faça seu login!</span>
                                </div>
                            </div> 
                        </FormsContainer>
                    </PageWrapperDesktop>
                </StyledDivDesktop>
                </>
            }
        </>
    )
}

export default CardRegister