import { useWhitelabel } from "../../../context/whitelabel.context"
import {useState, useEffect} from 'react'

export const HackingLoading = () => {
  const {textoAnalisando} = useWhitelabel()
  const [listaTexto, setListaTexto] = useState([textoAnalisando, "Estamos analisando, aguarde por favor", "Já está pronto pra fazer o carvão do fim de semana?", "Sinais sendo hackeados"])
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFadeOut(true);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % listaTexto.length);
        setFadeOut(false);
      }, 500); // Ajuste o tempo de espera conforme necessário
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [listaTexto]);

    return (
        <>
            <div className={`loader`}>
                <span></span>
            </div>

            <div className={`text ${fadeOut ? 'fade-out' : ''}`}>
                <h1 style={{color: '#FFF', fontSize: '14px', paddingTop: 10, opacity: 0.5}} >

                  {listaTexto[currentIndex]}

                </h1>
              
            </div>
            

            <style>
                {`
                    .loader, .text {
                      transition: opacity 0.5s ease-in-out;
                    }
          
                    .fade-out {
                      opacity: 0;
                    }
                    .loader {
                        position: relative;
                        width: 200px;
                        height: 200px;
                        background: transparent;
                        border-radius: 50%;
                        box-shadow: 25px 25px 75px rgba(0, 0, 0, 0.55);
                        border: 1px solid #333;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        overflow: hidden;
                      }
                      
                      .loader::before {
                        content: '';
                        position: absolute;
                        inset: 20px;
                        background: transparent;
                        border: 1px dashed #444;
                        border-radius: 50%;
                        box-shadow: inset -5px -5px 25px rgba(0, 0, 0, 0.25),
                            inset 5px 5px 35px rgba(0, 0, 0, 0.25);
                      }
                      
                      .loader::after {
                        content: '';
                        position: absolute;
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        border: 1px dashed #444;
                        box-shadow: inset -5px -5px 25px rgba(0, 0, 0, 0.25),
                            inset 5px 5px 35px rgba(0, 0, 0, 0.25);
                      }
                      
                      .loader span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 50%;
                        height: 100%;
                        background: transparent;
                        transform-origin: top left;
                        animation: radar81 2s linear infinite;
                        border-top: 1px dashed #fff;
                      }
                      
                      .loader span::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: var(--Primary-color);
                       /* pink */
                        transform-origin: top left;
                        transform: rotate(-55deg);
                        filter: blur(30px) drop-shadow(20px 20px 20px var(--Primary-color));
                       /* pink */
                      }
                      
                      @keyframes radar81 {
                        0% {
                          transform: rotate(0deg);
                        }
                      
                        100% {
                          transform: rotate(360deg);
                        }
                      }
                `}
            </style>

        </>
    )
}