import React from "react";
import CardRedefinePassword from "../../components/CardRedefinePassword";
import "../../index.css"

const RedefinePassword = () => {
    return (
        <>
            <CardRedefinePassword/>
        </>
    )
}

export default RedefinePassword