const obj = {
    ativo: "1",
    atualizacao: "aguardando status",
    data: "11/30/2023 4:37:43 PM",
    jogadaId: "6915524",
    roletaId: "60",
    texto:  "🚨 👀 <b>ANALISANDO</b> 👀 🚨 \r\n🎰 <b>Roleta</b>: Roulette Azure 1 - Pragmatic\r\n‼️ <b>Padrão</b>: Sequência de Números Primeira Coluna \r\n🚥 <b>[10, 19, 28]</b>\r\n🎲 <b>Estratégia</b>: Apostar na 2ª e na 3ª coluna\r\n\r\r\n \r\n♠️ <a href=\"https://abre.ai/hujV\">Mesa</a>  <a href=\"https://bit.ly/49UFc57\">📥 Cadastro</a>"
}

// "
// ✅✅ <b>GREEN!!! </b> 💰💰 <b>[18]</b>
// "


const SepararDadosRegex = (obj) => {
    // console.log(obj)
    const padraoAnalise = /<b>(.*?)<\/b>/;
    const padraoRoleta = /<b>Roleta<\/b>: (.*?)\n/;
    const padraoNumeros = /<b>\[(.*?)\]<\/b>/;
    const padraoEstrategia = /<b>Estratégia<\/b>:\s*([^<]*)/;

    const resultadoAnalise = (obj.texto.match(padraoAnalise) || [])[1];
    const resultadoRoleta = (obj.texto.match(padraoRoleta) || [])[1];
    const resultadoNumeros = (obj.texto.match(padraoNumeros) || [])[1];
    const resultadoEstrategia = (obj.texto.match(padraoEstrategia) || [])[1];

    return {
        estado: obj.texto.includes("GREEN") ? "GREEN" : obj.texto.includes("RED") ? "RED" : resultadoAnalise,
        mesa: resultadoRoleta,
        sequencia: resultadoNumeros,
        estrategia: resultadoEstrategia,
        ativo: obj.ativo
    }
}





export default SepararDadosRegex




