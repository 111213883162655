import React from "react";
import CardForgotPassword from "../../components/CardEsqueciSenha";
import "../../index.css"

const ForgotPassword = () => {
    return (
        <>
            <CardForgotPassword/>
        </>

    )
}

export default ForgotPassword