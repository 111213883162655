import React from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryLabel, PrimaryTextInput, PrimaryButton, Logo1, } from "../../globalComponents";
import { StyledDiv, PageWrapper, FormsContainer, Forms, ForgotPassword, StyledDivDesktop, PageWrapperDesktop } from "./styles";


import { useNavigate } from 'react-router-dom';
import { useWhitelabel } from "../../context/whitelabel.context";


const CardForgotPassword = () => {
    const {isMobile} = useControles()
    const {logoVertical} = useWhitelabel()
    const navigate = useNavigate()

    return (
        <>
            {isMobile ? 
                <>
                <StyledDiv>
                    <PageWrapper>

                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 150, maxHeight: 150}}/>
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 0, paddingBottom: 30,}}>
                            <h1 style={{fontSize: 36}}>Recupere</h1>
                            <p>sua senha</p>
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Email</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu email"></PrimaryTextInput>
                                </div>
                                
                            </Forms>

                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton>Enviar email de redefinição</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5,}}>
                                    <p></p>
                                        <span onClick={()=>{
                                            navigate(`/${localStorage.getItem('@Subdominio:BetBuilder')}`)
                                        }}>  Voltar</span>
                                </div>
                                
                                
                            </div>
                           

                            
                        </FormsContainer>
                    </PageWrapper>
                </StyledDiv>
                </> : 
                <>
                <StyledDivDesktop>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 200, maxHeight: 200}}/>
                    </div>

                    <PageWrapperDesktop>

                        
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, paddingBottom: 30, maxWidth: '420px', width: '80%'}}>
                            <h1 style={{fontSize: 30, textAlign: 'left', maxWidth: '420px'}}>
                                Recupere sua senha
                            </h1>
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Email</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu email"></PrimaryTextInput>
                                </div>
                            </Forms>

                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton>Enviar email de redefinição</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5,}}>
                                        <span onClick={()=>{
                                            navigate(`/${localStorage.getItem('@Subdominio:BetBuilder')}`)
                                        }}>  Voltar</span>
                                </div>
                                
                                
                            </div>
                           

                            
                        </FormsContainer>
                    </PageWrapperDesktop>
                </StyledDivDesktop>
                </>
            }
        </>
    )
}

export default CardForgotPassword