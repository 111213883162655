import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    padding: 0 0 50px 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;

    .divPai {
        margin-left: 250px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 250px);
        align-self: right;
        justify-content: center;
        padding: 40px 20px;

        iframe {
            border-radius: 20px;
            height: 280px;
            @media screen and (max-width: 640px) {
                height: 180px; /* Altura para dispositivos móveis */
            }
        }
    }

    .aulas {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        padding-top: 20px;

        @media screen and (max-width: 1024px) {
            flex-direction: column; /* Altera para um flex column em dispositivos menores que 1024px */
            align-items: center;
            justify-content: center;
        }

        @media screen and (min-width: 1025px) {
            display: grid; /* Altera para um grid em desktop */
            grid-template-columns: repeat(3, 1fr); /* 3 colunas */
        }
    }

    h1 {
        color: var(--Primary-text);
    }

    @media screen and (max-width: 1024px) {
        height: 100%;
        .divPai {
            width: 100%;
            margin-left: 0;
            align-items: center;
            justify-content: center;
        }
    }

    @media screen and (max-width: 770px) {
        .divPai {
            width: 100%;
            margin-left: 0;
            align-items: center;
            justify-content: center;
        }
        h1{
            text-align: left;
            width: 100%;
        }
    }
`;
