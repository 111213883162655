import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useControles } from "../../../context/controles.context";
import { ListarDepositos, ListarSaques, MinhasApostas } from "../../../services/betfive";
import { formatarData } from "../../../utils/formataData";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '15px 5px',
    width: '90%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
    background: 'var(--Secondary-Background)',
    borderRadius: '8px',
    gap: 2,
    
  };

const ModalMeusSaques = () => {
    const {open, setOpen, tituloModal, urlModal, setopenMeusSaques, openMeusSaques} = useControles();

    const [lista, setlista] = useState([]);

    const handleOpen = () => setopenMeusSaques(true);
    const handleClose = () => setopenMeusSaques(false);

    const fetchMeusSaques = async () => {
        const response = await ListarSaques()
        console.log(response.data)
        setlista(response.data)
    }

    useEffect(() => {
        fetchMeusSaques()
    }, [])
  
    return (
      <div>
        <Modal
          open={openMeusSaques}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h2 style={{color: 'var(--Primary-text)', fontSize: '32px'}}>Meus Saques</h2>
            <div style={{display: 'flex', flexDirection: 'column', overflowY: 'scroll', width: '100%', gap: '5px'}}>
              {lista.length > 1 ? 
              <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                  <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell align="right">Valor</TableCell>
                      <TableCell align="right">Status</TableCell>
                      
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {lista.map((row) => (
                      <TableRow
                      key={Math.random()}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                      <TableCell component="th" scope="row">
                          {formatarData(row.created_at)}
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                      <TableCell align="right">{row.status == "PAID" ? "Pago" : row.status == "REFUSED" ? "Recusado" : row.status}</TableCell>

                      </TableRow>
                  ))}
                  </TableBody>
              </Table>
          </TableContainer>
          :
            <div style={{display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center', color: 'white', height: '100%', alignItems: 'center'}}>
                <h2 style={{padding: '50px 0', opacity: '0.6'}}>Não há saques.</h2>
            </div>
          }
            
            </div>
          </Box>
        </Modal>
      </div>
    )
}

export default ModalMeusSaques