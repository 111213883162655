import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useControles } from "../../context/controles.context";
import { PrimaryButton } from "../../globalComponents";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '15px 5px',
    width: '90%',
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'var(--Secondary-Background)',
    borderRadius: '8px',
    gap: 2,
    
  };

const ModalIframe = () => {
    const {open, setOpen, tituloModal, urlModal} = useControles();

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    
  
    return (
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h2 style={{color: 'var(--Primary-text)', fontSize: '32px'}}>{tituloModal}</h2>
            <iframe src={urlModal} style={{width: '95%', height: '95%', border: '2px solid var(--Primary-color)', borderRadius: '8px'}}/>
            
          </Box>
        </Modal>
      </div>
    )
}

export default ModalIframe