import React from "react";
import CardLive from "../../components/CardLive";

const Live = () => {
    return (
        <>
            <CardLive/>
        </>
    )
}

export default Live