import React from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryLabel, PrimaryTextInput, PrimaryButton, Logo1, } from "../../globalComponents";
import { StyledDiv, PageWrapper, FormsContainer, Forms, ForgotPassword, StyledDivDesktop, PageWrapperDesktop } from "./styles";
import { useParams } from "react-router-dom";

import LogoVertical from '../../assets/logoVertical.png'
import { useNavigate } from 'react-router-dom';


const CardRedefinePassword = () => {
    const {isMobile} = useControles()
    const {token} = useParams()
    const navigate = useNavigate()

    return (
        <>
            {isMobile ? 
                <>
                <StyledDiv>
                    <PageWrapper>

                        
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 0, paddingBottom: 30}}>
                            <h1 style={{fontSize: 36}}>Redefina sua senha</h1>
                            
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Nova Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua nova senha"></PrimaryTextInput>
                                </div>
                                <div>
                                    <PrimaryLabel>Confirmar Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Confirme sua senha"></PrimaryTextInput>
                                </div>
                                
                            </Forms>

                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton>Redefinir</PrimaryButton>
                            </div>
                           

                            
                        </FormsContainer>
                    </PageWrapper>
                </StyledDiv>
                </> : 
                <>
                <StyledDivDesktop>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img src={LogoVertical} style={{width: '100%', height: '100%', maxWidth: 200, maxHeight: 200}}/>
                    </div>

                    <PageWrapperDesktop>

                        
                  
                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, paddingBottom: 30, maxWidth: '420px', width: '80%'}}>
                            <h1 style={{fontSize: 30, textAlign: 'left', maxWidth: '420px'}}>
                                Redefina sua senha
                            </h1>
                        </div>
                        
                        <FormsContainer>
                            <Forms>
                                <div>
                                    <PrimaryLabel>Nova Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua nova senha"></PrimaryTextInput>
                                </div>
                                <div>
                                    <PrimaryLabel>Confirmar Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Confirme sua senha"></PrimaryTextInput>
                                </div>
                            </Forms>
                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton>Redefinir</PrimaryButton>
                            </div>
                        </FormsContainer>
                    </PageWrapperDesktop>
                </StyledDivDesktop>
                </>
            }
        </>
    )
}

export default CardRedefinePassword