import React from "react";
import CardWizard from "../../components/CardWizard";
import "../../index.css"

const Wizard = () => {
    return (
        <>
            <CardWizard/>
        </>
    )
}

export default Wizard