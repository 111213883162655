import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    padding-bottom: 100px;
    

    .cardFundo{
        width: 95%;
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        border-radius: 20px;
        
    }

    .fotoPerfil{
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: grey;
        border-radius: 100px;

    }

    .icon{
        color: var(--Primary-text);
        font-size: 100px;
    }

    .alterarSenha{
        color: var(--Primary-text);
        opacity: 0.3;
        width: 100%;
        text-align: right;
        align-self: right;
    }



    .form{
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 40px;
    }
    .conjunto{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }

    .first{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding-bottom: 40px;
    }


    h2{
        color: var(--Primary-text);
        font-weight: bold;
        font-size: 32px;
    }

    .buttons{
        display: flex;
        justify-content: space-between;
        
        width: 100%;
        align-items: center;

    }
`;

export const ContainerDesktop = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100vh;
    padding-left: var(--SideBar-width);
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .cardFundo {
        width: 80%;
        max-width: 60rem;
        padding: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border-radius: 20px;
        margin-top: var(--Header-height);
        gap: 20px;
    }

    .fotoPerfil{
        width: 150px;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: grey;
        border-radius: 100px;

    }

    .icon{
        color: var(--Primary-text);
        font-size: 100px;
    }

    .form{
        display: flex;
        flex-direction: column;
        width: 30rem;
        gap: 40px;
    }
    .conjunto{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 5px;
    }

    .first{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding-bottom: 10rem;
    }


    h2{
        color: var(--Primary-text);
        font-weight: bold;
        font-size: 2em;
    }

    .buttons{
        display: flex;
        justify-content: space-between;
        
        width: 100%;
        align-items: center;

    }
`;