import React, {useState, useEffect} from "react";
import { useControles } from "../../context/controles.context";
import {DivPai} from './styles'
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { useWhitelabel } from "../../context/whitelabel.context";

const NavBar = () => {
    const {isMobile, activePage, setActivePage} = useControles()
    const navigate = useNavigate()
    const {liveBool} = useWhitelabel()

    


    const handleChangePage = (page) => {
        navigate(`./${page}`)
    
    }


    return(
        <DivPai>
            <div onClick={(e)=>{
                e.preventDefault()
                handleChangePage('Menu')
                setActivePage(1)
                }}>
                <Icon icon="ph:house-fill" className="icon" 
                    style={{
                    color: activePage == 1 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                    }}/>
                <span className="icon" 
                style={{
                color: activePage == 1 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                }}>
                    Menu
                </span>
            </div>

            <div onClick={(e)=>{
                e.preventDefault()
                handleChangePage('Games')
                setActivePage(2)
                
                }}>
                <Icon icon="material-symbols:playing-cards" className="icon" 
                    style={{
                    color: activePage == 2 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                    }}/>
                <span className="icon" 
                style={{
                color: activePage == 2 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                }}>
                    Jogos
                </span>
            </div>

            <div onClick={(e)=>{
                e.preventDefault()
                handleChangePage('Academy')
                setActivePage(3)
                }}>
                <Icon icon="vaadin:academy-cap" className="icon" 
                    style={{
                    color: activePage == 3 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                    }}/>
                <span className="icon" 
                style={{
                color: activePage == 3 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                }}>
                    Aulas
                </span>
            </div>

            

            <div onClick={(e)=>{
                e.preventDefault()
                handleChangePage('Perfil')
                setActivePage(4)
                }}>
                <Icon icon="iconamoon:profile-fill" className="icon" 
                    style={{
                    color: activePage == 4 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                    }}/>
                <span className="icon" 
                style={{
                color: activePage == 4 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                }}>
                    Perfil
                </span>
            </div>

            {liveBool == 1 &&
                <div onClick={(e)=>{
                    e.preventDefault()
                    handleChangePage('Live')
                    setActivePage(5)
                    }}>
                    <Icon icon="fluent:live-20-filled" className="icon" 
                        style={{
                        color: activePage == 5 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                        }}/>
                    <span className="icon" 
                    style={{
                    color: activePage == 5 ? 'var(--Primary-color)' : 'var(--Toggle-color)'
                    }}>
                        Live
                    </span>
                </div>
            }
        </DivPai>
    )
}

export default NavBar