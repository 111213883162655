import React, { useState, useEffect } from "react";
import { Container } from './styles'
import {BackgroundWrapper} from "../../globalComponents";
import { useControles } from "../../context/controles.context";
import { useWhitelabel } from "../../context/whitelabel.context";

const CardLive = () => {
    const {isMobile} = useControles()
    const {liveLink} = useWhitelabel()
    return (
        <>
        <Container>
            <div style={{marginLeft: !isMobile && 'var(--SideBar-width)', marginTop: 'var(--Header-height)'}}>
                <div style={{display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center', justifyContent: 'center', paddingTop: 20}}>
                    
                        <>
                            <h2 style={{color: 'white', width: isMobile ? '95vw' : '600px', textAlign: 'left'}} >Ao vivo</h2>
                            <iframe style={{width: isMobile ? '95vw' : '600px', height: isMobile ? '300px' : '400px'}} src={liveLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </>
                    
                </div>
                
            </div>
        </Container>

        <BackgroundWrapper>
            <div className="bgImage">
            </div>
        </BackgroundWrapper>
        </>
    )
}

export default CardLive