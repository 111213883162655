import styled from "styled-components";

export const DivPai = styled.div`
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 20px 25px 20px;
    border-radius: 20px 20px 0px 0;
    background: var(--Background-navBar);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    gap: 0px;

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;
        cursor: pointer;
    }
    div span{
        font-size: 12px !important;
    }
    div .icon{
        font-size: 24px;
    }
`;