import React, { createContext, useContext, useEffect, useState } from "react";

const ControlesContext = createContext({})
ControlesContext.displayName = 'ControlesContext'

const ControlesProvider = ({ children }) => {
    //verificaçao se é desktop ou mobile
    const [errorMsg, setErrorMsg] = useState()
    const [isMobile, setIsMobile] = useState()
    const [activeWizardStep, setActiveWizardStep] = useState(1)
    const [activePage, setActivePage] = useState(1) //1 - inicio / 2 - games / 3 - Academy / 4 - Perfil
    const [open, setOpen] = useState(false);
    const [tituloModal, setTituloModal] = useState()
    const [urlModal, setUrlModal] = useState()
    
    const [isLoading, setIsLoading] = useState()

    const [sideBarStatus, setSideBarStatus] = useState(true)
    const [headerStatus, setHeaderStatus] = useState(true)
    const [wizardAuxiliar, setWizardAuxiliar] = useState()

    const [openMinhasApostas, setopenMinhasApostas] = useState(false)
    const [openMeusSaques, setopenMeusSaques] = useState(false)
    const [openMeusDepositos, setopenMeusDepositos] = useState(false)
    const [openDeposit, setopenDeposit] = useState(false)
    const [openSaque, setopenSaque] = useState(false)

    const [loadingDeposit, setloadingDeposit] = useState(true)
    const [loadingWithdraw, setloadingWithdraw] = useState(false)

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1024)
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    

    return (
        <ControlesContext.Provider value={{     
               isMobile,
               setActiveWizardStep,
               activeWizardStep,
               activePage,
               setActivePage,
               setOpen,
               open,
               urlModal,
               setUrlModal,
               tituloModal,
               setTituloModal,
               setErrorMsg,
               errorMsg,
               setSideBarStatus,
               setHeaderStatus,
               headerStatus,
               sideBarStatus,
               isLoading,
               setIsLoading,
               wizardAuxiliar,
               setWizardAuxiliar,
               setopenMinhasApostas,
               openMinhasApostas,
               openMeusSaques, setopenMeusSaques,
               openMeusDepositos, setopenMeusDepositos,
               openDeposit, setopenDeposit,
               openSaque, setopenSaque,
               loadingDeposit, setloadingDeposit,
               loadingWithdraw, setloadingWithdraw
               
        }}>
            {children}
        </ControlesContext.Provider>
    )
}


export const useControles = () => {
    const context = useContext(ControlesContext)

    if(!context) {
        throw new Error("Erro ao usar o useControles")
    }

    return context;
}

export {ControlesContext, ControlesProvider}