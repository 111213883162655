import React, {useEffect, useState} from "react";
import { useControles } from "../../../context/controles.context";
import { useNavigate } from "react-router-dom";
import { useGame } from "../../../context/games.context";

const ItemGame = ({urlIcone, titulo, roletaId, tipoMesa, linkJogo, integracao, jogo}) => {
    const { isMobile } = useControles()
    const {setActiveGame} = useGame()
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/SalaDeJogos/${roletaId}/${integracao}/${jogo}`)
        setActiveGame({
            jogo: titulo,
            urlIcone: urlIcone,
            roletaId: roletaId,
            tipoMesa: tipoMesa,
            linkJogo: linkJogo,
            integracao: integracao,
            jogo: jogo
        })
    }

     return (
        <>
            <img 
                src={urlIcone} 
                style={{width: '100%', height: '100%', borderRadius: '8px', cursor: 'pointer'}}
                onClick={handleClick}
            /> 
        </>
    )
}

export default ItemGame

