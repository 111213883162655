import React, { useEffect, useState } from "react";
import CardSalaDeJogos from "../../components/CardSalaDeJogos";

const SalaDeJogos = () => {
    return (
        <>  
            <CardSalaDeJogos/>
        </>
    ) 
}

export default SalaDeJogos