import styled from "styled-components";

export const Container = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-direction: flex-end;
    align-items: center;
    padding-top: var(--Header-height);
    padding-bottom: 5rem;
    h1{
        color: var(--Primary-text);
        font-size: 22px;
        text-shadow: 2px 2px 4px rgba(170, 164, 164, 0.5);

    }

    @media screen and (max-width: 1024px){
        height: 100%;
       
        .categoria{
            padding-top: 50px;
        }

        .categoria:first-child{
            padding-top: 0;
        }
    }

    .tiuloCategoria{
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        text-align: left;
        color: var(--Primary-color);
        gap: 10px;
        font-size: 24px;
        text-shadow: 2px 2px 4px var(--Primary-color);
    }

    .jogos{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        padding-top: 20px;
    }

    

    @media screen and (max-width: 1024px){
    
    

        .jogos{
            align-items: center;
            justify-content: center;
            gap: 15px;
        }
    }


`;

export const DivPai = styled.div`
    z-index: 1;
    position: relative;
    margin-left: var(--SideBar-width);
    width: calc(100% - var(--SideBar-width));
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

