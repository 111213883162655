import React from "react";
import CardPerfil from "../../components/CardPerfil";
import "../../index.css"

const Perfil = () => {
    return (
        <>
            <CardPerfil/>
        </>
    )
}

export default Perfil