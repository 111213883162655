import React, { useEffect } from "react";
import {Container} from './styles'
import ItemGame from "./ItemGame";
import IconeJogo from '../../assets/imgCardGame.png'
import { useGame } from "../../context/games.context";
import { Icon } from '@iconify/react';
import CategoriaGame from "./CategoriaGame";
import { BackgroundWrapper } from "../../globalComponents";
import { useControles } from "../../context/controles.context";

const CardGames = () => {
    const { GetJogos, listaJogos } = useGame()
    const {setSideBarStatus} = useControles()

    useEffect(()=>{
        GetJogos()
        setSideBarStatus(true)
    }, [])

    return (
        <>
        <Container className="container">
                <div className="divPai">
                    <CategoriaGame categoria={0}/>
                    <CategoriaGame categoria={3}/>
                    <CategoriaGame categoria={2}/>    
                </div>
        </Container>
        <BackgroundWrapper>
            <div className="bgImage">
            </div>
        </BackgroundWrapper>
        </>
    )
}

export default CardGames