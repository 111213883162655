import React, {useEffect, useState} from "react";
import { useControles } from "../../context/controles.context";
import CardStepOne from "./CardStepOne";
import CardStepTwo from "./CardStepTwo";
import CardStepCodigo from "./CardStepCodigo";

const CardWizard = () => {
    const {isMobile, activeWizardStep} = useControles(1)

    return (
        <>
        {activeWizardStep == 1 ?
            <CardStepCodigo/>
        : activeWizardStep == 2 ?
            <CardStepOne/> :
        activeWizardStep == 3 && 
            <CardStepTwo/>
        }

        </>
    )
}

export default CardWizard