import React from "react";
import { useControles } from "../../../context/controles.context";
import { ErrorMsg, PrimaryButton, PrimaryLabel, PrimaryTextInput } from "../../../globalComponents";
import { WizardContainer, PageWrapper, PageWrapperDesktop, StyledDiv, StyledDivDesktop, IframeContainer, IframeContainerMobile, LogoMobile, Forms, FormsContainer } from "./styles";


import { useNavigate } from 'react-router-dom';
import { useWhitelabel } from "../../../context/whitelabel.context";
import Checkbox from '@mui/material/Checkbox';
import { useAuth } from "../../../context/auth.context";
import { useEffect, useState } from "react";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const CardStepCodigo = () => {
    const {linkCadastro, logoHorizontal} = useWhitelabel()
    const {isMobile, setActiveWizardStep, setWizardAuxiliar, errorMsg, setErrorMsg} = useControles()
    const {UpdateCodigoAfiliado} = useAuth()
    const [checkbox, setCheckbox] = useState(false)
    const navigate = useNavigate()

    const [codigo, setCodigo] = useState()


    

    const fetchData = async () => {
        const response = await UpdateCodigoAfiliado(codigo)
        return response
    }

    const handleValidarCodigo = async () => {
        await fetchData().then((res)=>{
            //console.log(res)
            setErrorMsg(null)
            setActiveWizardStep(2)
        }).catch(err => {
            //console.log(err)
            setErrorMsg(err, "err")
        })
    }

    const handleKeyPress = (event) => {
        
        if(event.code == 'Enter'){
            handleValidarCodigo()
        }
    }

    return (
        <>  
            <StyledDiv>
                    <LogoMobile>
                            <img src={logoHorizontal} style={{width: 150}}></img>
                    </LogoMobile>
                    <PageWrapper>
                            <h2 style={{padding: 40, textAlign: 'center'}}>
                                Acesse com seu código de influenciador
                            </h2>
                    <FormsContainer>
                            
                            <Forms>
                                <div>
                                    <PrimaryLabel>Código de Influenciador</PrimaryLabel>
                                    <PrimaryTextInput value={codigo} onChange={(e)=>setCodigo(e.target.value)} onKeyDown={handleKeyPress} placeholder="Informe seu código"/>
                                </div>
                            </Forms>

                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30, textAlign: 'center'}}>
                                {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                                <PrimaryButton style={{minWidth: 200}} onClick={handleValidarCodigo}>Prosseguir</PrimaryButton>
                               
                                    
                                <span style={{textAlign: 'center', textDecoration: 'underline'}} onClick={()=>{
                                    setActiveWizardStep(2)
                                }}>  Não tenho código</span>

                            </div>
                        </FormsContainer>
                    </PageWrapper>
                </StyledDiv>
                
        </>
    )
}

export default CardStepCodigo