import React from "react";
import { useWhitelabel } from "../../../context/whitelabel.context";
import { Icon } from '@iconify/react';

const LoadingMain = () => {
    const {logo, coresRoot, loadedWhitelabel} = useWhitelabel()
    return (
        <>
            <Icon icon="mdi:cards-playing" className="loadingMain" style={{color: 'var(--Primary-color)'}} />

            <style>
            {`
              .loadingMain {
                animation: jumpAndRotate 2s ease-in-out infinite;
                width: 100px;
                height: 100px;
                object-fit: contain;
              }

              @keyframes jumpAndRotate {
                0% {
                  transform: translateY(0) rotate(0deg);
                }
                20% {
                  transform: translateY(-20px) rotate(0deg);
                }
                50% {
                  transform: translateY(0) rotate(360deg);
                }
                100% {
                  transform: translateY(0) rotate(360deg);
                }
              }
              `}
            </style>
        </>
    )
}

export default LoadingMain