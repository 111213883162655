import React from "react";
import CardMenu from "../../components/CardMenu";
import "../../index.css"

const Menu = () => {
    return (
        <>
            <CardMenu/>
        </>
    )
}

export default Menu