import React, { useState, useEffect } from "react";
import { Container, ContainerDesktop } from "./styles";
import { useControles } from "../../context/controles.context";
import { PrimaryButton, PrimaryLabel, PrimaryTextInput, BackgroundWrapper } from "../../globalComponents";
import { Icon } from '@iconify/react';

import {useAuth} from '../../context/auth.context'
import { useWhitelabel } from "../../context/whitelabel.context";
import { useNavigate } from "react-router-dom";
import { Deposit, GetSaldo, MinhasApostas, Statistics } from "../../services/betfive";
import ModalTabela from "../ModalTabela";

const CardPerfil = () => {
    const {isMobile,setSideBarStatus, openMinhasApostas, setopenMinhasApostas, openMeusSaques, setopenMeusSaques, openMeusDepositos, setopenMeusDepositos} = useControles()
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('123456')
    const [telefone, setTelefone] = useState('')
    const {GetInfoPerfil, setAuthStatus} = useAuth()
    const {clienteId, linkAjuda, dominio} = useWhitelabel()
    const navigate = useNavigate()

    
    const Depositar = async () => {
        const response = await Deposit()
        console.log(response, " => response")
    }

   

    useEffect(()=>{
        // Depositar()
        setSideBarStatus(true)
        // console.log(JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder')))
        console.log(JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder')).name, " emaill")
        setNome(JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder')).name)
        setEmail(JSON.parse(localStorage.getItem('@UserBetfive:Betbuilder')).email)
    }, [])

    const handleMinhasApostas = () => {
        // window.open(linkAjuda, '_blank')
        setopenMinhasApostas(true)
    }

    const handleHistoricoDeposito = () => {
        setopenMeusDepositos(true)
    }

    const handleHistorioSaques = () => {
        setopenMeusSaques(true)
    }

    const SignOut = () => {
        localStorage.removeItem('@Email:BetBuilder')
        localStorage.removeItem('@Nome:BetBuilder')
        localStorage.removeItem('@Telefone:BetBuilder')
        sessionStorage.removeItem('@Token:BetBuilder')
        setAuthStatus(false)
        navigate(`/${localStorage.getItem('@Subdominio:BetBuilder')}`)

        localStorage.removeItem('@UserBetfive:Betbuilder')
        localStorage.removeItem('MobToken')
        localStorage.removeItem('saldo')
        localStorage.removeItem('tokenBetfive')
        localStorage.removeItem('horarioLogin')
        
    }

    return (
        <>
            {isMobile ? 
                <>
                <Container className="container" style={{paddingBottom: 100}}>
                    <>
                    <div className="cardFundo">
                        <div className="first">
                            <h2>Meu Perfil</h2>
                            <div className="fotoPerfil">
                                <Icon icon="iconamoon:profile-fill" className="icon" />
                            </div>
                        </div>

                        <div className="form">
                            <div className="conjunto">
                                <PrimaryLabel>Nome</PrimaryLabel>
                                <PrimaryTextInput placeholder={nome} disabled={true}/>
                            </div>
                            <div className="conjunto">
                                <PrimaryLabel>Email</PrimaryLabel>
                                <PrimaryTextInput placeholder={email} disabled={true}/>
                            </div>
                            {/* <div className="conjunto">
                                <PrimaryLabel>Telefone</PrimaryLabel>
                                <PrimaryTextInput placeholder={telefone} disabled={true}/>
                            </div> */}
                            <div className="conjunto">
                                <PrimaryLabel>Senha</PrimaryLabel>
                                <PrimaryTextInput type="password" value={password} disabled={true}/>
                                {/* <span className="alterarSenha">Alterar sua senha</span> */}
                            </div>

                            <div className="buttons" style={{gap: 4}}>
                                <ModalTabela/>
                                <PrimaryButton style={{width: '100%'}} onClick={handleHistorioSaques}>
                                    Meus Saques
                                </PrimaryButton>
                                <PrimaryButton style={{width: '100%'}} onClick={handleHistoricoDeposito}>
                                    Meus depósitos
                                </PrimaryButton>
                                
                                <PrimaryButton style={{width: '100%'}} onClick={SignOut}>
                                    Sair
                                </PrimaryButton>
                            </div>
                        </div>
                    </div>
                    </>
                </Container>
                <BackgroundWrapper>
                    <div className="bgImage">
                    </div>
                </BackgroundWrapper>
                </>
                :
                <>
                <ContainerDesktop className="container">
            <div className="cardFundo">
                <div className="first">
                    <h2>Meu Perfil</h2>
                    <div className="fotoPerfil">
                        <Icon icon="iconamoon:profile-fill" className="icon" />
                    </div>
                    <ModalTabela/>
                </div>

                <div className="form">
                    <div className="conjunto">
                        <PrimaryLabel>Nome</PrimaryLabel>
                        <PrimaryTextInput placeholder={nome} disabled={true}/>
                    </div>
                    <div className="conjunto">
                        <PrimaryLabel>Email</PrimaryLabel>
                        <PrimaryTextInput placeholder={email} disabled={true}/>
                    </div>
                    {/* <div className="conjunto">
                        <PrimaryLabel>Telefone</PrimaryLabel>
                        <PrimaryTextInput placeholder={telefone} disabled={true}/>
                    </div> */}
                    <div className="conjunto">
                        <PrimaryLabel>Senha</PrimaryLabel>
                        <PrimaryTextInput type="password" value={password} disabled={true}/>
                    </div>

                    <div className="buttons" style={{gap: 4}}>
                        
                        <PrimaryButton style={{width: '100%'}} onClick={handleHistorioSaques}>
                            Meus Saques
                        </PrimaryButton>

                        <PrimaryButton style={{width: '100%'}} onClick={handleHistoricoDeposito}>
                            Meus depósitos
                        </PrimaryButton>
                        
                        <PrimaryButton style={{width: '100%'}} onClick={SignOut}>
                            Sair
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </ContainerDesktop>
        <BackgroundWrapper>
            <div className="bgImage">
            </div>
        </BackgroundWrapper>
        </>
            } 

            
        </>

    )
}

export default CardPerfil