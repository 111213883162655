import React from "react";

const ItemVideo = ({ aulaLink, titulo }) => {
  // const embedUrl = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div style={{ width: "100%"}}>
        <div
          style={{
            color: "white",
            padding: "8px 8px",
            fontSize: "16px",
            fontWeight: '500'
          }}
        >
          {titulo}
        </div>
      <div style={{ position: "relative", paddingBottom: "8px"}}>
        <iframe
          width="100%"
          src={aulaLink}
          frameBorder="0"
          allowFullScreen
          title="Embedded Video"
          style={{minHeight: '250px'}}
        ></iframe>
      </div>
    </div>
  );
};

export default ItemVideo;
