import { TextField } from "@mui/material";
import styled from "styled-components";
import InputMask from 'react-input-mask';
import backgroundPattern from "./assets/pattern.svg"
import backgroundImage from "./assets/gradient.webp"

//Aqui fazemos os componentes que iremos usar grande parte das vezes
export const ContainerNotSignedMobile = styled.div`

`;

export const Separator = styled.div`
    width: 100%;
    height: 1.5px;
    background-color: var(--Secondary-Background);
    border-radius: 50%;
`;

export const PrimaryButtonNoRadius = styled.button`
    background: var(--Primary-linear);
    padding: 16px 0;
    font-size: 16px;
    color: var(--Primary-text);
    
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 4px;
    flex-shrink: 0;
    cursor: pointer;
    
    &:hover {
        opacity: 0.9;
    }

    &:active {
        transform: scale(1.01);
    }
`

export const PrimaryButton = styled.button`
    background: var(--Primary-linear);
    padding: 16px 0;
    font-size: 16px;
    color: var(--Primary-text);
    
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 48px;
    
    cursor: pointer;
    
    &:hover {
        opacity: 0.9;
    }

    &:active {
        transform: scale(1.01);
    }
`

export const SecondaryButton = styled.button`
    background: transparent;
    padding: 16px 0;
    font-size: 16px;
    color: var(--Primary-color);
    
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--Primary-color);
    border-radius: 48px;
    flex-shrink: 0;
    cursor: pointer;
    
    &:hover {
        opacity: 0.9;
    }

    &:active {
        transform: scale(1.01);
    }
`
export const SecondaryButtonNoRadius = styled.button`
    background: transparent;
    padding: 16px 0;
    font-size: 16px;
    color: var(--Primary-color);
    
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--Primary-color);
    border-radius: 4px;
    flex-shrink: 0;
    cursor: pointer;
    
    &:hover {
        opacity: 0.9;
    }

    &:active {
        transform: scale(1.01);
    }
`

export const PrimaryLabel = styled.span`
    font-family: var(--Primary-font);
    color: var(--Primary-text) !important;
    font-weight: 500;
    font-style: normal;
`;

export const PrimaryTextInput = styled.input`
    border: 1px solid #fff !important; 
    color: var(--Primary-text) !important;
    outline: none !important;
    border-radius: 8px;
    background: var(--Primary-background) !important;
    padding: 15px;
    opacity: 0.4;
    

    &::placeholder{
        color: var(--Primary-text) !important; 
    }

    label{
        color: var(--Primary-text) !important;
        outline: none !important;
    }

    &:focus{
        outline: none !important;
    }

`;

export const PrimaryInputMask = styled(InputMask)`
    border: 1px solid #fff !important; 
    color: var(--Primary-text) !important;
    outline: none !important;
    border-radius: 8px !important;
    background: var(--Primary-background) !important;
    padding: 15px;
    opacity: 0.4;
    

    &::placeholder{
        color: var(--Primary-text) !important; 
    }

    label{
        color: var(--Primary-text) !important;
        outline: none !important;
    }

    &:focus{
        outline: none !important;
    }

`;

export const Logo1 = styled.div`
    width: 150px;
    height: 150px;
`;

export const BackgroundWrapper = styled.section`
    z-index: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: linear-gradient(rgba(0,0,0,.25),rgba(0,0,0,.25)),linear-gradient(transparent 74%,var(--Primary-Background)),url(${backgroundPattern});
    background-position: 0 0,0 0,0 0;
    background-size: auto,auto,4px;
    position: absolute;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: auto;

    .bgImage {
        width: 300%;
        height: 100%;
        opacity: .5;
        filter: var(--Matiz-background);
        background-image: url(${backgroundImage});
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
    }
`;


export const ErrorMsg = styled.span`
    color: var(--Primary-color);
    font-weight: 500;
    font-size: 14px;
    padding: 0;
    margin: 0;
`;