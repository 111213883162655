import React, {useEffect} from "react";
import CardLogin from "../../components/CardLogin";
import "../../index.css"
import { useWhitelabel } from "../../context/whitelabel.context";
import { VerifSubDomain } from "../../utils/verifSubdomain";
import { GetSaldo, LoginBetfive } from "../../services/betfive";

const Login = () => {
    const {loadedWhitelabel, dominio, setDominio, VerifySubDomain} = useWhitelabel()
    
    const fetchData = async () => {
        const response = await LoginBetfive()
        console.log(JSON.parse(response.data.loginResponse))
    }

    useEffect(()=>{
        // fetchData();

        localStorage.removeItem('@ClientId:BetBuilder')
          const dominio = VerifSubDomain()
          setDominio(dominio)
          localStorage.setItem('@Subdominio:BetBuilder', dominio)
          VerifySubDomain(localStorage.getItem('@Subdominio:BetBuilder'))
      }, [])
    return (
        <>
            <CardLogin/>
        </>
    )
}

export default Login