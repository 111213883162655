import React, { useEffect } from "react";
import { useControles } from "../../context/controles.context";
import {Container} from './styles'
import ItemVideo from "./ItemVideo";
import { Separator, BackgroundWrapper } from "../../globalComponents";
import { useCliente } from "../../context/cliente.context";

const CardAcademy = () => {
    const {isMobile} = useControles()
    const {aulas, GetAulas, setAulas} = useCliente()

    const fetchData = async () => {
        const response = await GetAulas()
        //console.log(response, "aulas")
        setAulas(response)
    }   
    
    useEffect(()=>{
        fetchData()
    }, [])

    return (
        <>
            {isMobile ? 
                <>
                    <Container className="container">
                            <div className="divPai">
                                <h1>Aulas</h1>
                                
                                <div className="aulas">
                                    {aulas && aulas.map((a)=>{
                                        return(
                                            <>
                                                <ItemVideo aulaLink={a.aulaLink} titulo={a.nomeCard}/>
                                                <Separator/>
                                            </>
                                        )
                                    })}
                                    
                                    
                                </div>
                            </div>
                    </Container>
                    <BackgroundWrapper>
                        <div className="bgImage">
                            
                        </div>
                    </BackgroundWrapper>
                </> : 
                <>
                    <Container className="container">
                            <div className="divPai">
                                <h1>Aulas</h1>
                                
                                <div className="aulas">
                                    {aulas && aulas.map((a)=>{
                                        return(
                                            <>
                                                <ItemVideo aulaLink={a.aulaLink} titulo={a.nomeCard}/>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                    </Container>
                    <BackgroundWrapper>
                        <div className="bgImage">
                            
                        </div>
                    </BackgroundWrapper>
                </>
            }
        </>

    )
}

export default CardAcademy