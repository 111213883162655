import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import RedefinePassword from "../pages/RedefinePassword";
import Influencer from "../pages/Influencer";
import Menu from "../pages/Menu";

import Wizard from "../pages/Wizard";
import { SignedOutPages } from "../pages/SignedOutPages";
import { useAuth } from "../context/auth.context";

export default function NotSignedRoutes(){
        const {VerifAuth} = useAuth()

        

        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<SignedOutPages/>}>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/:afiliado" element={<Login/>}/>
                            <Route path="/Login" element={<Login/>}/>
                            <Route path="/Register" element={<Register/>}/>
                            <Route path="/ForgotPassword" element={<ForgotPassword/>}/>
                            <Route path="/RedefinePassword" element={<RedefinePassword/>}/>
                            <Route path="/RedefinePassword/:token" element={<RedefinePassword/>}/>
                            {/* <Route path="/Influencer" element={<Influencer/>}/> */}
                        </Route>

                        
                    </Routes>
                </BrowserRouter>
            </>
        )
    
}