import React from "react";
import CardGames from "../../components/CardGames";
import "../../index.css"

const Games = () => {
    return (
        <>
            <CardGames/>
        </>
    )
}

export default Games