import styled from "styled-components";

export const HeaderDiv = styled.div`
    position: fixed;
    z-index: 999;
    padding-top: env(safe-area-inset-top);
    display: flex;
    align-items: center;
    height: var(--Header-height);
    width: 100%;
    background-color: var(--Secondary-Background);
    border: 1px solid var(--Toggle-color);
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 0 10px;
    @media screen and (min-width: 1024px){
        height: var(--Header-height);
        padding: 0;
        border: 1px solid var(--Toggle-color);
        border-top: none;
        border-left: none;
        border-right: none;
        }
`;

export const HeaderContent = styled.div`
    display: flex;
    width: 100%;
    
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
`

export const HeaderLogoMobile = styled.div`
    display: flex;
    align-items: center;
    max-width: 150px;
`

export const HeaderButtons = styled.div`
    display: flex;
    align-items: center;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
`;

export const ProfileIcon = styled.div`
    background-color: grey;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: var(--Primary-text);
    width: 40px;
    height: 40px;
    border-radius: 2rem;
`;