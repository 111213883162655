import React, {useRef, useState} from "react";
import { useControles } from "../../context/controles.context";
import { PrimaryLabel, PrimaryTextInput, PrimaryButton, Logo1, ErrorMsg, } from "../../globalComponents";
import {
    StyledDiv,
    PageWrapper,
    FormsContainer,
    Forms,
    ForgotPassword,
    StyledDivDesktop,
    PageWrapperDesktop,
    RecaptchaContainer
} from "./styles";


import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/auth.context";
import { useWhitelabel } from "../../context/whitelabel.context";
import LoadingMain from "../Loadings/LoadingMain";
import { useEffect } from "react";
import { GetMobToken, LoginApiBetfive, LoginBetfive, LoginUsuarioBetfive, VerificaToken } from "../../services/betfive";
import ReCAPTCHA from "react-google-recaptcha";
import logoBetfive from '../../assets/logoBetfive.png'


const CardLogin = () => {
    const {isMobile, errorMsg, setErrorMsg, setIsLoading} = useControles()
    const {Login, statusWizard, GetBoolWizard, setAuthStatus, authStatus, AtualizarToken, setNewToken, newToken} = useAuth()
    const {clienteId, primaryBackgroundHex, logoVertical, linkCadastro} = useWhitelabel()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')
    const [isLoadingLogin, setIsLoadingLogin] = useState(false)

    const [tokenValido, setTokenValido] = useState()

    const [captchaValue, setCaptchaValue] = useState("string");

    const [showCaptcha, setShowCaptcha] = useState(false);

    const recaptchaRef = useRef(null);

    const handleKeyPress = (event) => {

        if (event.key === 'Enter' || event.code === 'Enter') {
            handleLogin();
        }
    }



    const handleLogin = async () => {
        if(!email){
            setErrorMsg("Insira seu username.")
        } else if (!senha){
            setErrorMsg("Insira sua senha.")
        } else {
            setErrorMsg(null)
            const userData = {
                id: 0,
                nome: '',
                email: email,
                senha: senha,
                clienteId: clienteId,
                username: email
            }
            setIsLoading(true)
            setIsLoadingLogin(true)

            await LoginUsuarioBetfive({
                username: email,
                password: senha,
                recaptchaToken: captchaValue
            }).then(async (res)=>{
                console.log("Res: ", res)
                if(res.data.message){
                    setErrorMsg("Usuario ou senha incorretos.")
                    setIsLoading(false)
                    setAuthStatus(false)
                    setIsLoadingLogin(false)
                    return
                } else {
                    setAuthStatus(true)
                    setIsLoading(false)
                    setIsLoadingLogin(false)
                    setTokenValido(res.data.token)
                    localStorage.setItem('@UserBetfive:Betbuilder', JSON.stringify(res.data))
                    localStorage.setItem('MobToken', res.data.token)
                    localStorage.setItem('saldo', res.data.credits)
                    localStorage.setItem('horarioLogin', new Date().getTime())
                    setNewToken(res.data.token)
                }

            }).catch((err) => {
                setIsLoading(false)
                setAuthStatus(false)
                setIsLoadingLogin(false)

                if(err.response.data === "Token de recaptcha inválido"){
                    setShowCaptcha(true)
                    setErrorMsg("Complete o captcha para continuar.")
                    recaptchaRef.current.reset();
                    return
                }

                console.log("Error: ", err)
                setErrorMsg("Usuario ou senha incorretos.")
            })

        }

    }

    const handleCaptcha = (value) => {
        setCaptchaValue(value);
    };

    useEffect(()=>{
        if(authStatus){
            if(statusWizard == '1' || statusWizard == 1){
                navigate('/wizard')
                setAuthStatus(true)
            } else {
                navigate('/')
                setAuthStatus(true)
            }
        }

    }, [statusWizard])

    const handleRegistro = () => {
        window.open(linkCadastro, 'blank')
    }

    return (
        <>
            {isMobile ?
                <>
                <StyledDiv>
                    <PageWrapper>

                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 150, maxHeight: 150}}/>

                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 0, paddingBottom: 30,}}>
                            <h1 style={{fontSize: 36}}>Bem vindo!</h1>
                            <p>Entre para continuar</p>
                        </div>

                        <FormsContainer>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <span style={{color: 'white', display: 'flex', alignItems: 'center', fontSize: '18px', textAlign: 'center'}}>
                                    Faça o login utilizando 
                                </span>
                                
                                <span style={{color: 'white', display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                                    sua conta da <img src={logoBetfive} style={{maxWidth: '90px'}}/>
                                </span>
                                
                            </div>
                            <Forms className="form-login">
                                <div>
                                    <PrimaryLabel>Username</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu username"
                                    value={email} onChange={(e)=>setEmail(e.target.value)} onKeyDown={handleKeyPress}/>
                                </div>

                                <div>
                                    <PrimaryLabel>Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua senha" type="password"
                                    value={senha} onChange={(e)=>setSenha(e.target.value)}
                                    onKeyDown={handleKeyPress}/>
                                </div>
                                  <RecaptchaContainer>
                                      <ReCAPTCHA
                                        size={'compact'}
                                        style={{display: showCaptcha ? 'block' : 'none'}}
                                        ref={recaptchaRef}
                                        sitekey="6LchX8ocAAAAAOmtnyxkiBaygfEH-Im-BshbMa6X"
                                        onChange={handleCaptcha}
                                      />
                                  </RecaptchaContainer>
                                <ForgotPassword onClick={()=>{
                                    // navigate('/ForgotPassword')
                                    handleRegistro()
                                }}>
                                    Esqueceu sua senha?
                                </ForgotPassword>
                            </Forms>

                            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>

                                <PrimaryButton onClick={handleLogin}>Entrar</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5,}}>
                                    <p>Caso não tenha uma conta, </p>
                                        <span onClick={()=>{
                                            //navigate('/Register')
                                            handleRegistro()
                                        }}>  faça seu cadastro!</span>
                                </div>


                            </div>



                        </FormsContainer>
                    </PageWrapper>
                </StyledDiv>
                </> :
                <>
                <StyledDivDesktop>
                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',    height: '100%' ,backgroundImage: 'var(--Background-image)', backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                        <img src={logoVertical} style={{width: '100%', height: '100%', maxWidth: 200, maxHeight: 200}}/>
                    </div>

                    <PageWrapperDesktop>



                        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', gap: 10, paddingBottom: 30,}}>
                            <h1 style={{fontSize: 30, textAlign: 'left', maxWidth: '420px'}}>
                                Estamos te esperando
                                para mais uma rodada!
                            </h1>

                            <p style={{textAlign: 'left'}}>Insira seus dados e comece a diversão!</p>
                        </div>

                        <FormsContainer>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <span style={{color: 'white', display: 'flex', alignItems: 'center', fontSize: '18px', textAlign: 'center'}}>
                                    Faça o login utilizando 
                                </span>
                                
                                <span style={{color: 'white', display: 'flex', alignItems: 'center', fontSize: '18px'}}>
                                    sua conta da <img src={logoBetfive} style={{maxWidth: '90px'}}/>
                                </span>
                                
                            </div>
                            <Forms className="form-login">
                                <div onKeyDown={handleKeyPress}>
                                    <PrimaryLabel>Username</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe seu username"
                                    value={email} onChange={(e)=>setEmail(e.target.value)}
                                    onKeyDownCapture={handleKeyPress}/>
                                </div>

                                <div>
                                    <PrimaryLabel>Senha</PrimaryLabel>
                                    <PrimaryTextInput placeholder="Informe sua senha" type="password"
                                    value={senha} onChange={(e)=>setSenha(e.target.value)} onKeyDown={handleKeyPress}/>
                                </div>
                                <RecaptchaContainer>
                                    <ReCAPTCHA
                                      size={'compact'}
                                      style={{display: showCaptcha ? 'block' : 'none'}}
                                      ref={recaptchaRef}
                                      sitekey="6LchX8ocAAAAAOmtnyxkiBaygfEH-Im-BshbMa6X"
                                      onChange={handleCaptcha}
                                    />
                                </RecaptchaContainer>
                                <ForgotPassword onClick={()=>{
                                    // navigate('/ForgotPassword')
                                    handleRegistro()
                                }}>
                                    Esqueceu sua senha?
                                </ForgotPassword>
                            </Forms>

                            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}


                            <div style={{paddingTop: 20, display: 'flex', flexDirection: 'column', gap: 30,}}>
                                <PrimaryButton onClick={handleLogin}>Entrar</PrimaryButton>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 5,}}>
                                    <p>Caso não tenha uma conta, </p>
                                        <span onClick={()=>{
                                            // navigate('/Register')
                                            handleRegistro()
                                        }}>  faça seu cadastro!</span>
                                </div>


                            </div>



                        </FormsContainer>
                    </PageWrapperDesktop>
                </StyledDivDesktop>
                </>
            }
            {isLoadingLogin &&
                <div style={{position: 'absolute', top: '0%', left: '0%', background: '#00000066', width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <div style={{position: 'absolute', top: '45%'}}>
                        <LoadingMain/>
                    </div>
                </div>
            }
        </>
    )
}

export default CardLogin
